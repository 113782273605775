
    import {defineComponent, ref, onMounted, reactive} from 'vue';
    import {getList, close} from '@/network/customer';
    import {message} from "ant-design-vue";
    import moment from 'moment';
    import baseUrl from '@/util/api'

    interface DataItem {
        id: number;
        sn: string;
        sku: string;
        file: string;
        type: number;
        description: string;
        fixer: number;
        time: number;
        priority: number;
        status: number;
        result: number;
        create_time: number;
        useTime:number;
    }

    interface WhereItem {
        sn: string;
        type: string | undefined;
        status: string | undefined;
        page: number;
        size: number;
        role: string | undefined;
    }

    export default defineComponent({
        name: "Clist",
        setup() {
            const data = ref<DataItem[]>([]);
            const where = ref<WhereItem>({
                sn: '',
                type: undefined,
                status: undefined,
                page: 1,
                size: 20,
                role: undefined,
            });
            const columns = [
                {title: 'ID', width: 80, dataIndex: 'id', key: 'id', fixed: 'left'},
                {title: '马帮编号', width: 150, dataIndex: 'sn', key: 'sn', fixed: 'left'},
                {title: '产品sku', dataIndex: 'sku', key: 'sku', width: 150},
                {
                    title: '产品截图', dataIndex: 'file', key: 'file', width: 100, slots: {
                        customRender: 'file',
                    },
                },
                {
                    title: '工单类型', dataIndex: 'type', key: 'type', width: 100, slots: {
                        customRender: 'type',
                    }, sorter: (a, b) => a.type - b.type,
                },
                {title: '工单内容', dataIndex: 'description', key: 'description', width: 200},
                {title: '处理人部门', dataIndex: 'role', key: 'role', width: 100, slots: {
                        customRender: 'role',
                    }},
                {title: '工单处理人', dataIndex: 'uname', key: 'uname', width: 100},
                {
                    title: '要求处理时长(分钟)', dataIndex: 'time', key: 'time', width: 100, slots: {
                        customRender: 'time',
                    }, sorter: (a, b) => a.time - b.time,
                },
                {
                    title: '优先级别', dataIndex: 'priority', key: 'priority', width: 100, slots: {
                        customRender: 'priority',
                    }, sorter: (a, b) => a.priority - b.priority,
                },
                {
                    title: '工单状态', dataIndex: 'status', key: 'status', width: 100, slots: {
                        customRender: 'status',
                    }, sorter: (a, b) => a.status - b.status,
                },
                {
                    title: '处理时长(分钟)', dataIndex: 'useTime', key: 'useTime', width: 100, slots: {
                        customRender: 'useTime',
                    }, sorter: (a, b) => a.useTime - b.useTime,
                },
                {
                    title: '备注', dataIndex: 'comment', key: 'comment', width: 100
                },
                {
                    title: '处理结果', dataIndex: 'result', key: 'result', width: 100, slots: {
                        customRender: 'result',
                    }, sorter: (a, b) => a.result - b.result,
                },
                {
                    title: '创建时间', dataIndex: 'create_time', key: 'create_time', width: 200,
                    customRender: (text, record, index) => {
                        return moment.unix(text.text).format('YYYY-MM-DD HH:mm:ss');
                    }, sorter: (a, b) => a.create_time - b.create_time,
                },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 120,
                    slots: {customRender: 'action'},
                },
            ];
            const loading = ref(false);
            const list = (where) => {
                loading.value = true;
                getList({'uid': window.localStorage.getItem('uid'), ...where}).then((response) => {
                    loading.value = false;
                    if (response.status == 'done') {
                        data.value = response.list;
                        pagination.total = response.total;
                        //message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            };
            const handleChange = (value: string) => {
                //console.log(value); // { key: "lucy", label: "Lucy (101)" }
                //console.log(typeof value); // { key: "lucy", label: "Lucy (101)" }
                where.value.type = value;
            };
            const handleChange1 = (value: string) => {
                //console.log(value); // { key: "lucy", label: "Lucy (101)" }
                where.value.status = value;
            };
            const handleChange2 = (value: string) => {
                //console.log(value); // { key: "lucy", label: "Lucy (101)" }
                where.value.role = value;
            };
            const search = () => {
                where.value.page = 1;
                list(where.value);
            };
            const pagination = reactive({
                total: 0,
                pageSize: 20,
            });
            const handleTableChange = (pagination, filters, sorter) => {
                where.value.page = pagination.current;
                list(where.value);
            };
            const onClose = (id) => {
                close({'id': id}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                        list(where.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            };
            onMounted(() => {
                list(where.value);
            });
            return {
                loading,
                data,
                columns,
                where,
                handleChange,
                handleChange1,
                handleChange2,
                search,
                handleTableChange,
                pagination,
                onClose,
                baseUrl:baseUrl.apiUrl,
            };
        },
    });
